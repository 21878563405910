import { Box, ChakraProvider, Flex, Heading, Text, Container, Avatar, useColorModeValue } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import SwiperCore, { Navigation } from 'swiper';

SwiperCore.use([Navigation]); // Navigation aktivieren


const Testimonial = ({ children }) => {
    return <Box>{children}</Box>;
};

const TestimonialContent = ({ children }) => {
    return (
        <Box
            bg={useColorModeValue('white', 'gray.800')}
            boxShadow={'lg'}
            p={8}
            rounded={'xl'}
            align={'center'}
            pos={'relative'}
            _after={{
                content: `""`,
                w: 0,
                h: 0,
                borderLeft: 'solid transparent',
                borderLeftWidth: 16,
                borderRight: 'solid transparent',
                borderRightWidth: 16,
                borderTop: 'solid',
                borderTopWidth: 16,
                borderTopColor: useColorModeValue('white', 'gray.800'),
                pos: 'absolute',
                bottom: '-16px',
                left: '50%',
                transform: 'translateX(-50%)',
            }}
        >
            {children}
        </Box>
    );
};

const TestimonialHeading = ({ children }) => {
    return <Heading as={'h3'} fontSize={'xl'}>{children}</Heading>;
};

const TestimonialText = ({ children }) => {
    return (
        <Text
            textAlign={'center'}
            color={useColorModeValue('gray.600', 'gray.400')}
            fontSize={'sm'}
        >
            {children}
        </Text>
    );
};

const TestimonialAvatar = ({ src, name, title }) => {
    return (
        <Flex align={'center'} mt={8} direction={'column'}>
            <Avatar src={src} alt={name} mb={2} />
            <Flex spacing={-1} align={'center'}>
                <Text fontWeight={600}>{name}</Text>
                <Text fontSize={'sm'} color={useColorModeValue('gray.600', 'gray.400')}>
                    {title}
                </Text>
            </Flex>
        </Flex>
    );
};

export default function Testimonials() {
    return (
        <ChakraProvider>
            <Box bg={useColorModeValue('gray.100', 'gray.700')}>
                <Container maxW={'7xl'} py={16}>
                    <Box textAlign="center" mb={8}>
                        <Heading>Unsere Kunden sprechen</Heading>
                        <Text>Wir arbeiten mit Kunden auf der ganzen Welt zusammen</Text>
                    </Box>
                    <Swiper
                        spaceBetween={10}
                        slidesPerView={1}
                        breakpoints={{
                            768: {
                                slidesPerView: 3,
                            },
                        }}
                        navigation={{
                            prevEl: '.swiper-button-prev',
                            nextEl: '.swiper-button-next',
                        }}

                    >
                        <div className="swiper-button-prev" />
                        <div className="swiper-button-next" />
                        <SwiperSlide>
                            <Testimonial>
                                <TestimonialContent>
                                    <TestimonialHeading>Effiziente Zusammenarbeit</TestimonialHeading>
                                    <TestimonialText>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Auctor neque sed imperdiet nibh lectus feugiat nunc sem.
                                    </TestimonialText>
                                </TestimonialContent>
                                <TestimonialAvatar
                                    src="https://images.unsplash.com/photo-1586297135537-94bc9ba060aa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=100&q=80"
                                    name="Jane Cooper"
                                    title="CEO bei ABC Corporation"
                                />
                            </Testimonial>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Testimonial>
                                <TestimonialContent>
                                    <TestimonialHeading>Intuitives Design</TestimonialHeading>
                                    <TestimonialText>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Auctor neque sed imperdiet nibh lectus feugiat nunc sem.
                                    </TestimonialText>
                                </TestimonialContent>
                                <TestimonialAvatar
                                    src="https://images.unsplash.com/photo-1586297135537-94bc9ba060aa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=100&q=80"
                                    name="Jane Cooper"
                                    title="CEO bei ABC Corporation"
                                />
                            </Testimonial>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Testimonial>
                                <TestimonialContent>
                                    <TestimonialHeading>Intuitives Design</TestimonialHeading>
                                    <TestimonialText>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Auctor neque sed imperdiet nibh lectus feugiat nunc sem.
                                    </TestimonialText>
                                </TestimonialContent>
                                <TestimonialAvatar
                                    src="https://images.unsplash.com/photo-1586297135537-94bc9ba060aa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=100&q=80"
                                    name="Jane Cooper"
                                    title="CEO bei ABC Corporation"
                                />
                            </Testimonial>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Testimonial>
                                <TestimonialContent>
                                    <TestimonialHeading>Beeindruckender Service</TestimonialHeading>
                                    <TestimonialText>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Auctor neque sed imperdiet nibh lectus feugiat nunc sem.
                                    </TestimonialText>
                                </TestimonialContent>
                                <TestimonialAvatar
                                    src="https://images.unsplash.com/photo-1586297135537-94bc9ba060aa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=100&q=80"
                                    name="Jane Cooper"
                                    title="CEO bei ABC Corporation"
                                />
                            </Testimonial>
                        </SwiperSlide>
                    </Swiper>
                </Container>
            </Box>
        </ChakraProvider>
    );
}
