import './App.css';
import Cards from './Cards';
import NavBar from './NavBar';
import Features from './Features';
import Testimonial from './Testimonial';
import Footer from './Footer';
import { Text, UnorderedList, ListItem, theme, Divider, Button, Highlight, ButtonGroup, Heading, Box, ChakraProvider, Grid } from "@chakra-ui/react";

function App() {
  return (
    <ChakraProvider>

      <Box maxW="1200px" mx="auto" p={4}>
        <NavBar />
        <Grid mt={10} templateColumns="1fr 1fr" gap={4}>
          <Box mt={10}>
            {/* Hier kommt der Textinhalt */}
            <Heading as='h2' size='2xl'>
              Angstfrei durchs leben
            </Heading>
            <UnorderedList fontSize='lg'  mt={5}>
      <ListItem>Punkt 1</ListItem>
      <ListItem>Punkt 2</ListItem>
      <ListItem>Punkt 3</ListItem>
    </UnorderedList>
            <Text mt={5} fontSize='lg'>Wie du Angststörungen überwinden kannst und wieder Selbstvertrauen gewinnst</Text>
            <p></p>
            <Button mt={10} colorScheme='orange' as="a" href="https://constanze-potthast.de/termin-buchen/" target="_blank">Termin vereinbaren</Button>
          </Box>
          <Box>
            {/* Hier kommt das Video */}
            <video src="dein-video.mp4" controls width="100%" height="auto" style={{ borderRadius: '10px', boxShadow: '0px 0px 10px 2px rgba(0, 0, 0, 0.2)' }} />
          </Box>
        </Grid>
        <Box mt={20}>
          {/* Hier kommen die Cards */}



        </Box>

        <Grid mt={10} templateColumns="1fr 1fr" gap={4}>

          <Box mt={20}>
            <p>test</p>

          </Box>

          <Box mt={20}>
            <p>Heilpraktikt</p>

          </Box>

        </Grid>

      </Box>
      <Features />
      <Features />
      <Features />
      <Testimonial />
      < Footer />
    </ChakraProvider>

  );
}

export default App;
