import React from 'react'

import {
    Box,
    Container,
    Stack,
    Text,
    Link,
    useColorModeValue,
} from '@chakra-ui/react';



const Footer = () => {
    return (
        <Box
            bg={useColorModeValue('gray.50', 'gray.900')}
            color={useColorModeValue('gray.700', 'gray.200')}>
            <Container
                as={Stack}
                maxW={'6xl'}
                py={4}
                direction={{ base: 'column', md: 'row' }}
                spacing={4}
                justify={{ base: 'center', md: 'space-between' }}
                align={{ base: 'center', md: 'center' }}>
                <Stack direction={'row'} spacing={6}>
                    <Link fontSize={12} href={'https://constanze-potthast.de/impressum'}>Impressum</Link>
                    <Link fontSize={12} href={'https://constanze-potthast.de/datenschutz'}>Datenschutz</Link>
                    <Link fontSize={12} href={'https://constanze-potthast.de/geschaeftsbedingungen'}>AGB</Link>
                </Stack>
                <Text fontSize={12}>© 2022 Chakra Templates. All rights reserved</Text>
            </Container>
        </Box >
    );
}

export default Footer